<template>
    <b-container fluid>
        <div class="card">
            <div class="card-body">
                <div class="row mb-2">
                    <div class="col-md-3">
                        <storage-picker :disabled="!!loading" v-model="filters.storage"/>
                    </div>
                    <div class="col-md-3">
                        <date-time-picker
                            locale='ru'
                            input-size='sm'
                            position='bottom'
                            label='Дата от'
                            id='dateFrom'

                            :max-date="filters.dateTo"
                            :no-label='true'
                            :no-header='true'
                            :only-date='true'
                            :no-button='true'
                            :auto-close='true'
                            :no-clear-button='true'
                            :disabled="!!loading"
                            v-model='filters.dateFrom'

                            format='YYYY-MM-DD'
                            formatted='YYYY-MM-DD'
                        />
                    </div>
                    <div class="col-md-3">
                        <date-time-picker
                            locale='ru'
                            input-size='sm'
                            position='bottom'
                            label='Дата до'
                            id='dateTo'

                            :min-date="filters.dateFrom"
                            :no-label='true'
                            :no-header='true'
                            :only-date='true'
                            :no-button='true'
                            :auto-close='true'
                            :no-clear-button='true'
                            :disabled="!!loading"
                            v-model='filters.dateTo'

                            format='YYYY-MM-DD'
                            formatted='YYYY-MM-DD'
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <button :disabled="!(filters.storage && filters.dateFrom && filters.dateTo) || !!loading"
                                @click="cookingTemperature()"
                                class="btn btn-success">
                            <i v-if="!!loading && !!loading.cookingTemperature" class="fas fa-spinner fa-spin"></i>
                            <i v-else class="fas fa-download"></i>
                            <span>Температура готовки</span>
                        </button>
                    </div>
                    <div class="col-md-4">
                        <button :disabled="!(filters.storage && filters.dateFrom && filters.dateTo) || !!loading"
                                @click="producedNomenclatures()"
                                class="btn btn-success">
                            <i v-if="!!loading && !!loading.producedNomenclatures" class="fas fa-spinner fa-spin"></i>
                            <i v-else class="fas fa-download"></i>
                            <span>Производимые п/ф</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
    import lsfMixin from "@utils/localStorageFilterMixin";
    import DateTimePicker from 'vue-ctk-date-time-picker';
    import StoragePicker from "@components/_common/StoragePicker";
    import {REPORTS} from "@utils/endpoints";
    import CSV from '@utils/csv';

    const FILTER_PATTERN = {
        dateFrom: null,
        dateTo: null,
        storage: null,
    };
    export default {
        name: 'Index',
        mixins: [lsfMixin],
        components: {
            DateTimePicker,
            StoragePicker
        },
        data() {
            return {
                filters: {
                    dateFrom: null,
                    dateTo: null,
                    storage: null,
                },
                loading: null,
            };
        },
        methods: {
            cookingTemperature() {
                if (!!this.loading) {
                    return;
                }
                this.loading = {
                    cookingTemperature: true
                };
                this.download(REPORTS + '/production_task/temperature/cooking', 'Температура при готовке');
            },
            producedNomenclatures() {
                if (!!this.loading) {
                    return;
                }
                this.loading = {
                    producedNomenclatures: true
                };
                this.download(REPORTS + '/production_task/nomenclature/produced', 'Производимые пф');
            },
            download(url, namePrefix) {
                this.$http.get(url, {
                    params: {
                        date_from: this.filters.dateFrom,
                        date_to: this.filters.dateTo,
                        storage_id: this.filters.storage.id,

                        without_loading: true,
                    }
                }).then(response => {
                    let name = namePrefix + ' ' + this.filters.storage.name + ' ' + this.filters.dateFrom + this.filters.dateTo;
                    CSV.download(response.data, name);
                }).catch(response => {
                    if (!!response.data && !!response.data.message) {
                        this.$toast.error(response.data.message);
                    } else {
                        console.log(response);
                        this.$toast.error('Ошибка!');
                    }
                }).finally(() => {
                    this.loading = null;
                });
            }
        },
        created() {
            this.initFilters(this, FILTER_PATTERN);
        }
    }
</script>
